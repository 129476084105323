export const columnsDataNews = [
  {
    Header: "SITE NAME",
    accessor: "news_room_name",
  },
  {
    Header: "POST TITLE",
    accessor: "title",
  },
  {
    Header: "VIEWS",
    accessor: "views_count",
  },
  {
    Header: "POSTING DATE",
    accessor: "createdAt",
  },
  // {
  //   Header: "URL SLUG",
  //   accessor: "url_slug",
  // },
  {
    Header: "CONTENT",
    accessor: "body",
  },
  {
    Header: "ACTION",
    accessor: "",
  },
];
