import {
  Flex,
  Table,
  Button,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Icon,
  Link,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import IconBox from "components/icons/IconBox";
import { IoMdEye, IoIosAdd } from "react-icons/io";
import { sythesizeBlog } from "api/ApiUtils";

// Custom components
import Card from "components/card/Card";
import Modal from "./Modal";

export default function CheckTable(props) {
  const { columnsData, tableData } = props;
  const [isOpenState, setIsOpenState] = useState(false);
  const [activeNewsItem, setActiveNewsItem] = useState([]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const handleClick = (cell) => {
    setActiveNewsItem(cell?.row?.cells);
    setIsOpenState(!isOpenState);
  };

  const handlePublishClick = async () => {
    let data = {};
    activeNewsItem.map((cell, index) => {
      if (cell.column.Header === "CONTENT") {
        data.content = cell.value;
      } else if (cell.column.Header === "POST TITLE") {
        data.title = cell.value;
      }
    });
    data.prompt = data.content;
    let response = await sythesizeBlog(data);
    if (response.data.data.status.success == "true") {
      setIsOpenState(false);
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 100;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const buttonColor = useColorModeValue("Gray.900", "gray");
  const boxBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandColor = useColorModeValue("brand.500", "brand.400");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex alignItems="center" w="100%" mb="30px">
        <IconBox
          me="12px"
          w="38px"
          h="38px"
          bg={boxBg}
          icon={<Icon as={IoMdEye} color={brandColor} w="24px" h="24px" />}
        />
        <Text color={textColor} fontSize="lg" fontWeight="700">
          Coinjupiter News feed
        </Text>
        <IconBox
          me="12px"
          w="38px"
          h="38px"
          bg={boxBg}
          ms="auto"
          icon={<Icon as={IoIosAdd} color={brandColor} w="24px" h="24px" />}
        />
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "SITE NAME") {
                    data = (
                      <Flex align="center">
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "POST TITLE") {
                    data = (
                      <Flex align="center">
                        <Link
                          me="10px"
                          color={textColor}
                          fontSize="sm"
                          href={cell.value}
                          fontWeight="700"
                        >
                          {cell.value}
                        </Link>
                      </Flex>
                    );
                  } else if (cell.column.Header === "VIEWS") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "CONTENT") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "POSTING DATE") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "URL SLUG") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ACTION") {
                    data = (
                      <Button
                        color={buttonColor}
                        fontSize="sm"
                        onClick={() => {
                          handleClick(cell);
                        }}
                        fontWeight="700"
                      >
                        {"Actions..."}
                      </Button>
                    );
                  } else {
                    return;
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
        <Modal
          open={isOpenState}
          handleClick={handleClick}
          handlePublishClick={handlePublishClick}
          modalTitle="Publish item"
          modalBody="This feature is still under development!"
        />
      </Table>
    </Card>
  );
}
